.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  .gallery-title {
    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 70px;
    color: white;
  }

  .ant-row {
    .ant-col {
      margin-right: 10px;
    }
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
}
