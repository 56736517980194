.shop {
    margin-top: 30px;
    margin-bottom: 150px;
    .shop-title{
        color: white;
        margin-top: 74px;
        margin-top: 74px;
    }

    .shop-text{
        color: white;
        text-align: center;
        font-size: 60px;
        margin-top: 70px;
    }

    @media screen and (max-width: 600px) {
        .shop-text{
            font-size: 40px;
        }
    }
}