.portfolio {
  background-color: #282828;

  padding-bottom: 30px;

  .portfolio-title {
    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 70px;
    color: white;
  }

  .portfolio-row {
    margin-bottom: 25px;

    &.mobile {
      display: none;
    }

    .ant-card-cover {
      img {
        max-height: 159px;
      }
    }

    .ant-card-actions {
      svg {
        width: 25px;
      }
    }

    @media screen and (max-width: 600px) {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;

        .portfolio-card{
          .ant-card{
            margin-bottom: 20px;
            width: 300px !important;

            .ant-card-cover{
              img{
                max-height: 400px;
              }
            }
          }
        }
      }
    }
  }
}
